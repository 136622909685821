<template>
  <div id="allergy-list">
    <!-- dialog -->

    <delete-confirmation
      v-model="isDeleteDialogActive"
      :selected-item="selectedItem"
      @delete-confirmation="submitDelete"
      @refetch-data="fetchAllergies"
    ></delete-confirmation>

    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="allergy-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="fetchNewCodes()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Fetch all Medispan Codes</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedAllergy"
        :headers="tableColumns"
        :items="allergyList"
        :options.sync="options"
        :search="searchQuery"
        :loading="loading"
      >
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="selectedItem = item"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = false, isAllergyFormActive = !isAllergyFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = true, isAllergyFormActive = !isAllergyFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountEditOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="selectItem(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountLockOutline }}
                  </v-icon>
                  <span>Disable</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isDeleteDialogActive = !isDeleteDialogActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiOfficeBuilding,
  mdiPlus,
  mdiSquareEditOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
} from '@mdi/js'

import allergyService from '@/services/AllergyService'
import DeleteConfirmation from '@/components/validation/DeleteConfirmation.vue'
import useAllergyList from '@/store/useAllergyService'

export default {
  components: { DeleteConfirmation },
  setup() {
    const ROLE_APP = 'app-allergy'

    // Register module
    if (!store.hasModule(ROLE_APP)) store.registerModule(ROLE_APP, allergyService)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROLE_APP)) store.unregisterModule(ROLE_APP)
    })

    const {
      allergyList,
      tableColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedAllergy,
      readCode,

      fetchAllergies,
      deleteAllergy,

      fetchMedispanAllergies,
    } = useAllergyList()

    const isEdit = ref(false)
    const selectedItem = ref()
    const isDeleteDialogActive = ref(false)
    const isAllergyFormActive = ref(false)

    return {
      allergyList,
      tableColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedAllergy,
      isAllergyFormActive,
      selectedItem,
      isEdit,
      isDeleteDialogActive,
      readCode,

      deleteAllergy,
      fetchAllergies,
      fetchMedispanAllergies,
      icons: {
        mdiAccountOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiOfficeBuilding,
        mdiPlus,
        mdiSquareEditOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
      },
    }
  },
  mounted() {
    this.fetchAllergies()
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item
      //console.log(this.selectedItem)
    },
    submitDelete(allergyDTO) {
      this.deleteAllergy(allergyDTO)
    },
    fetchNewCodes(){
      this.fetchMedispanAllergies()
    }
  },
}
</script>
