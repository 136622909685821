import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"allergy-list"}},[_c('delete-confirmation',{attrs:{"selected-item":_vm.selectedItem},on:{"delete-confirmation":_vm.submitDelete,"refetch-data":_vm.fetchAllergies},model:{value:(_vm.isDeleteDialogActive),callback:function ($$v) {_vm.isDeleteDialogActive=$$v},expression:"isDeleteDialogActive"}}),_c(VCard,[_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"allergy-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.fetchNewCodes()}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Fetch all Medispan Codes")])],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.allergyList,"options":_vm.options,"search":_vm.searchQuery,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.selectedItem = item}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{attrs:{"link":""}},[_c(VBtn,{attrs:{"text":"","x-small":""},on:{"click":function($event){_vm.isEdit = false, _vm.isAllergyFormActive = !_vm.isAllergyFormActive}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Details")])],1)],1),_c(VListItem,{attrs:{"link":""}},[_c(VBtn,{attrs:{"text":"","x-small":""},on:{"click":function($event){_vm.isEdit = true, _vm.isAllergyFormActive = !_vm.isAllergyFormActive}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountEditOutline)+" ")]),_c('span',[_vm._v("Edit")])],1)],1),_c(VListItem,{attrs:{"link":""}},[_c(VBtn,{attrs:{"text":"","x-small":""},on:{"click":function($event){return _vm.selectItem(item)}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountLockOutline)+" ")]),_c('span',[_vm._v("Disable")])],1)],1),_c(VListItem,{attrs:{"link":""}},[_c(VBtn,{attrs:{"text":"","x-small":""},on:{"click":function($event){_vm.isDeleteDialogActive = !_vm.isDeleteDialogActive}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedAllergy),callback:function ($$v) {_vm.selectedAllergy=$$v},expression:"selectedAllergy"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }